import React, { useEffect, useState } from 'react';
import {
  VStack,
  Pressable,
  Text,
  Box,
  Heading,
  Input,
  FormControl,
} from 'native-base';
import { FORM_ACTIONS } from '../../utils/formActions.js';
import { GOAL_ITEMS } from './constants';
import { shuffleArray } from './utils';

// we leave the "other" item out because we don't want that to be shuffled
const PARTIAL_GOAL_ITEMS = GOAL_ITEMS.slice(0, -1);

function PersonalInfo({ formData, dispatch }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [goalItems, setGoalItems] = useState([]);

  const toggleSelection = (data) => {
    if (selectedOptions.length < 3 && !selectedOptions.includes(data)) {
      setSelectedOptions((prevState) => [...prevState, data]);
      dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: data });
    }

    if (selectedOptions.includes(data)) {
      const index = selectedOptions.indexOf(data);
      const updatedSelections = selectedOptions.splice(index, 1);
      setSelectedOptions(updatedSelections);
      dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: data });
    }
  };

  useEffect(() => {
    // shuffle the array of goals but keep "other" as last element
    const partialGoalItems = shuffleArray(PARTIAL_GOAL_ITEMS);
    const shuffledGoalItems = [
      ...partialGoalItems,
      GOAL_ITEMS.at(GOAL_ITEMS.length - 1),
    ];
    setGoalItems(shuffledGoalItems);
  }, []);

  // set state to grey out options if three are selected
  useEffect(() => {
    if (selectedOptions.length === 3) {
      setIsMaxSelected(true);
    } else {
      setIsMaxSelected(false);
    }
  }, [selectedOptions]);

  useEffect(() => {
    const updateForm = () => {
      const initialValue = GOAL_ITEMS.filter((goal) => formData[goal.data]);
      const values = initialValue.map((value) => value.data);
      setSelectedOptions(values);
    };

    updateForm();
  }, [formData]);

  return (
    <VStack
      space={1}
      className="personal-info-container"
      testID={'personal-info'}
    >
      <VStack mb={6}>
        <Heading fontSize={'3xl'}>
          Welcome! What is your gut health goal?
        </Heading>
        <Heading fontFamily="body" fontWeight={'300'} size={'md'} mt={3}>
          What do you want to be able to do, with Ayble Health? Select up to
          three:
        </Heading>
      </VStack>

      {goalItems.map((item, index) => {
        const isItemSelected = !!formData[item.data];
        return (
          <Box key={index}>
            <Pressable
              key={index}
              onPress={() => toggleSelection(item.data)}
              testID={'personal-info-item'}
            >
              <Box
                opacity={!isItemSelected && isMaxSelected ? '0.5' : '1'}
                bg={isItemSelected ? 'secondary.500' : 'light.50'}
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
                p="5"
                mb={2}
              >
                <Text
                  color={isItemSelected ? 'light.50' : 'secondary.500'}
                  fontWeight="medium"
                  fontSize="md"
                >
                  {item.plain}
                </Text>
              </Box>
            </Pressable>

            {isItemSelected && item.hasValue && (
              <VStack>
                <FormControl isInvalid={isTouched && !formData.otherGoalValue}>
                  <FormControl.Label>Please specify:</FormControl.Label>
                  <Input
                    width="100%"
                    maxW={'1024px'}
                    marginBottom={0}
                    type="text"
                    testID={'personal-info-other-goal'}
                    value={formData.otherGoalValue}
                    onBlur={() => setIsTouched(true)}
                    onChangeText={(e) => {
                      setIsTouched(true);

                      dispatch({
                        type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                        fieldName: 'otherGoalValue',
                        payload: e,
                      });
                    }}
                  />
                  <FormControl.ErrorMessage mt={2}>
                    Please specify your goal or unselect this option
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
            )}
          </Box>
        );
      })}
    </VStack>
  );
}

export default PersonalInfo;
