// map containing the events that will be tracked once a given survey page is visited:
// the key is the page number of our survey, the value represents the tracking event
export const TRACKING_PAGE_EVENT_MAP = {
  '-2': '1_landing_page',
  '-1': '2_enterprise_signup_form',
  0: '3_regular_signup_form',
  1: '4_accomplish_question',
  2: '5_see_if_eligible_page',
  3: '6_sex_and_gender_question',
  4: '7_ethnicity_question',
  5: '8_pregnancy_question',
  6: '9_eating_disorder_question',
  7: '10_gi_surgery_question',
  8: 'symptoms_questions_list',
  9: '12_gi_symptoms_question',
  'assessment-a': '13_assessment_A',
  'assessment-b': '13_assessment_B',
  'assessment-c': '13_assessment_C',
  'assessment-d': '13_assessment_D',
  11: '18_last_step_page',
};

// the following events are not bounded to any specific survey page,
// so they are not in the map above but the live independently
export const TRACKING_START_SIGN_UP = 'signup_start';
export const TRACKING_SIGN_UP_SUCCEDEED = 'signup_succeeded';
export const TRACKING_SIGN_UP_FAILED = 'signup_failed';
export const TRACKING_PREGNANCY_REJECT = 'user_ineligible_3';
export const TRACKING_DISORDER_REJECT = 'user_ineligible_4';
export const TRACKING_GI_SURGERY_REJECT = 'user_ineligible_5';
export const TRACKING_MEMBERSHIP_PAGE = 'choose_membership';
export const TRACKING_STRIPE_PAYMENT_PAGE = 'stripe_payment_page';
export const TRACKING_DOWNLOAD_APP_PAGE = 'download_app_page';
export const TRACKING_FREE_CONSULTATION_LINK_CLICK = 'click_for_consultation';
export const TRACKING_FREE_CONSULTATION_HOME_LINK_CLICK =
  'home_click_for_consultation';
export const TRACKING_ELIGIBILITY_CHECK = 'check_member_eligibility';
export const TRACKING_PURCHASE_LOCAL_STORAGE_KEY = 'finishedOnboarding';

export const GOAL_ITEMS = [
  {
    plain: 'Identify foods triggering my symptoms',
    data: 'goal1',
  },
  {
    plain: 'Access a 24/7 care team in-between doctor visits',
    data: 'goal2',
  },
  {
    plain: 'Reduce disruption to daily life or social activities',
    data: 'goal3',
  },
  {
    plain: 'Track and improve my symptoms with better data and insights',
    data: 'goal4',
  },
  { plain: 'Receive personalized nutrition coaching', data: 'goal5' },
  { plain: 'Learn strategies to calm my mind and gut', data: 'goal6' },
  {
    plain: 'Other goal',
    data: 'otherGoal',
    hasValue: true,
  },
];
