import { Box, Text, VStack } from 'native-base';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { generateBulletPoints } from './bullet-points';
import { BulletListItem } from './BulletListItem';

export const MobileInfoHighlightedItem = ({
  highlightedBulletIndex = null,
}: {
  highlightedBulletIndex?: number | null;
}) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});
  const bulletPoints = generateBulletPoints(fetchedAffiliate);

  if (highlightedBulletIndex === null)
    return (
      <Text
        color={'white'}
        fontSize={['2xl', '3xl']}
        fontWeight={'normal'}
        pt={6}
        textAlign={'center'}
      >
        Everything your gut needs— join the Ayble experience!
      </Text>
    );

  const { bg, bgSize, imgUrl, text, title } =
    bulletPoints[highlightedBulletIndex];

  return (
    <BulletListItem
      bgColor={bg}
      bgSize={bgSize}
      highlighted={true}
      img={imgUrl}
      key={title}
      style={{ marginTop: 12 }}
    >
      <VStack>
        <Box pb={1}>{title}</Box>
        <Text fontSize={'lg'} fontWeight={'thin'}>
          {text}
        </Text>
      </VStack>
    </BulletListItem>
  );
};
