import React from 'react';
import { VStack, Box, Image, Heading, Button } from 'native-base';
import TestimonialCard from './TestimonialCard';

const blob = './../blob.png';
const womanTestimonial = '../../woman-testimonial.png';
const manTestimonial = '../../man-testimonial.png';

const testimonialContent = [
  {
    image: womanTestimonial,
    title: 'Ayble helped me feel like myself again.',
    body: "This is a game-changer in the world of digestive health management. I've tried all the conventional methods to manage my condition, but they all fell short. Ayble, on the other hand, was a breath of fresh air. It was fast, simple, and, most importantly, it identified the root cause of my problem. I no longer dread going out to eat.",
    bio: 'Tina, 36',
    numStars: 5,
    diagnosis: 'IBS',
  },
  {
    image: manTestimonial,
    title:
      'The coaching and emotional support were gold. And having your own health data is empowering!',
    body: "I appreciate the holistic approach. It's so different from a visit with a GI doctor, in a good way, where I feel supported while living with my Ulcerative Colitis. I also like the tactical goal setting with my care team. I have something to focus on coming out of here. It makes me feel hopeful!",
    bio: 'Mark, 51',
    numStars: 5,
    diagnosis: 'Ulcerative Colitis',
  },
];

function AssessmentWrapUp({ onNext }) {
  return (
    <VStack alignItems={'center'}>
      <Box
        mt={20}
        maxWidth={'700px'}
        w={'100%'}
        mb={'9'}
        minH="275"
        rounded={10}
        bg={'light.50'}
        px={12}
        py={8}
        alignItems="center"
      >
        <Box position={'absolute'} top={'-70px'}>
          <Image
            size={'160px'}
            alt="blob"
            style={{ backgroundSize: 'contain', resizeMode: 'contain' }}
            source={{
              uri: blob,
            }}
          />
          <Heading fontSize={'5xl'} top={'-100px'} position={'relative'}>
            90%
          </Heading>
        </Box>
        <Heading
          fontWeight="medium"
          fontSize="30px"
          mt={20}
          mb={5}
          mx={12}
          lineHeight={35}
        >
          Great news — 90% of people like you improve their GI symptoms with
          Ayble Health.
        </Heading>
        <Heading
          marginTop={'24px'}
          fontFamily={'body'}
          fontWeight={400}
          fontSize={'11px'}
          textAlign={'left'}
          color={'muted.500'}
        >
          *Source: Jactel, et. al 2021. Note: Results not guaranteed, your
          experience on Ayble Health may be different.
        </Heading>
      </Box>

      <Button minWidth={'300px'} onPress={onNext}>
        Start feeling better
      </Button>

      <div
        style={{
          marginTop: '90px',
          width: '100vw',
          display: 'flex',
          justifyContent: 'space-around',
          background:
            'linear-gradient(0deg, #9DD6E1 0%, #C5DFF8 40%, #D0E2FE 100%)',
        }}
      >
        <VStack space={4} py={'60px'} w={'70%'} maxW={990}>
          {testimonialContent.map((testimonial) => (
            <TestimonialCard key={testimonial.bio} testimonial={testimonial} />
          ))}
          <Button width={'300px'} onPress={onNext} alignSelf={'center'} mt={10}>
            Start feeling better
          </Button>
        </VStack>
      </div>
    </VStack>
  );
}

export default AssessmentWrapUp;
