import { Box, Flex, FormControl, Text } from 'native-base';

export const TOSInput = ({ tosChecked, submitted, handleTosChange }) => {
  const links = [
    {
      href: 'https://www.ayblehealth.com/eula',
      text: 'Terms & Conditions',
      end: ', ',
    },
    {
      href: 'https://www.ayblehealth.com/privacy-policy',
      text: ' Privacy Policy',
      end: ', and ',
    },
    {
      href: 'https://www.ayblehealth.com/privacy-policy',
      text: 'Notice of HIPAA Privacy Practices',
      end: '.',
    },
  ];

  return (
    <form name="tosForm">
      <FormControl isInvalid={!tosChecked && submitted}>
        <Flex flexDirection="row" style={{ alignItems: 'flex-start' }}>
          <input
            type="checkbox"
            name="TOS"
            id="TOS"
            checked={tosChecked}
            onChange={handleTosChange}
            data-testid="tos-checkbox"
          />

          <Box>
            <label
              htmlFor="TOS"
              style={{
                maxWidth: '450px',
                lineHeight: '25px',
                margin: '0 .5em',
              }}
            >
              By continuing, I agree that I am at least 18 years old and agree
              to Ayble's{' '}
              {links.map((link, index) => (
                <Box key={index} display={'inline'}>
                  <a href={link.href} target="_blank" rel="noreferrer">
                    {link.text}
                  </a>
                  <Text style={{ color: 'black', fontWeight: 400 }}>
                    {link.end}
                  </Text>
                </Box>
              ))}
            </label>
          </Box>
        </Flex>

        <FormControl.ErrorMessage h={4} ml={1} mt={0}>
          Please accept to terms and conditions before proceeding
        </FormControl.ErrorMessage>
      </FormControl>
    </form>
  );
};

export default TOSInput;
